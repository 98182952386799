import * as React from "react";
import { graphql } from "gatsby";

import { MarkdownRenderer } from "../common/Markdown";
import {
  SectionContainer,
  SectionContent,
  SectionWrapper
} from "../common/Section";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

import { CallToAction } from "../components/CallToAction";
import { TestimonialsCarousel } from "../components/Carousel";
import { BlogPostHero } from "../components/Hero";


interface Props {
  data: any
};


const ArticleTemplate: React.FC<Props> = ( props ) => {
  const {
    mdx: {
      body,
      frontmatter: {
        title,
        description,
        author,
        postDate,
        heroImage: {
          childImageSharp: {
            gatsbyImageData
          }
        }
      }
    },
    testimonials
  } = props.data;

  return (
    <Layout>
      <Head
        title={ title }
      />

      <SectionWrapper
        background="linear-gradient(#FBF7F1 0%, #FBF7F1 65%, white 65%, white 100%)"
      >
        <BlogPostHero
          heading={ title }
          author={ author }
          postDate={ postDate }
          image={ gatsbyImageData }
          alt=""
        />
      </SectionWrapper>

      <SectionWrapper>
        <SectionContainer>
          <MarkdownRenderer
            body={ body }
            description={ description }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            heading="Our service and professionals have earned over 3,700+ reviews!"
            headingWidth={{
              base: '100%',
              lg: '700px'
            }}
            headingMargin="0 auto"
            description={[
              "Vester Pest Control has helped thousands of property owners over the past 20 years. Here are what some of our customers have to say."
            ]}
            descriptionWidth={{
              base: '100%',
              sm: '580px'
            }}
          />
        </SectionContainer>
        <TestimonialsCarousel
          nodes={ testimonials.edges }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading="Request a Free Quote"
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query Article($id: String) {
    mdx(id: {
      eq: $id
    }) {
      id
      body
      frontmatter {
        title
        description
        author
        postDate(
          formatString: "MMMM Do, YYYY"
        )
        heroImage {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }

    testimonials: allMdx(
      filter: {
        frontmatter: {
          template: {
            eq: "testimonial"
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            source
            reviewer
            review
          }
        }
      }
    }
  }
`;


export default ArticleTemplate;
